import React, { useCallback } from "react";

import { DEVICE_TYPE } from "../../../domain/device";
import { SENSOR_TYPE } from "../../../domain/sensor";
import FormattedNumber from "../../../elements/FormattedNumber";
import FormattedDate from "../../../elements/FormattedDate";
import Status from "./Status";
import FormattedIdent from "../../../elements/FormattedIdent";
import I18nText from "../../../elements/I18nText";

const StatusContainer = React.memo(({ device }) => {
  const getOptions = useCallback(() => {
    const options = [
      {
        label: "devices.common.type",
        value: (
          <I18nText>{`devices.common.typeOptions.${device.type}`}</I18nText>
        )
      }
    ];

    if (device.type === DEVICE_TYPE.SMS) {
      options.push({
        label: `devices.common.identTypes.${device.identType}`,
        value: (
          <FormattedIdent type={device.identType}>
            {device.ident}
          </FormattedIdent>
        )
      });

      if (device.simCreditQty !== undefined && device.simCreditQty !== null) {
        options.push({
          label: "devices.common.simCreditQty",
          value: (
            <>
              <FormattedNumber decimals={2}>
                {device.simCreditQty}
              </FormattedNumber>{" "}
              {device.simCreditCurrency}
            </>
          )
        });
      }
    }

    if (device.type === DEVICE_TYPE.LX_IOT) {
      options.push({
        label: "devices.common.identTypes.iccid",
        value: <FormattedIdent type="iccid">{device.iccid}</FormattedIdent>
      });
    }

    if (device.type === DEVICE_TYPE.IOT) {
      options.push({
        label: "devices.common.identTypes.thing_name",
        value: <FormattedIdent type="thing_name">{device.ident}</FormattedIdent>
      });

      if (device.iccid) {
        options.push({
          label: "devices.common.identTypes.iccid",
          value: <FormattedIdent type="iccid">{device.iccid}</FormattedIdent>
        });
      }

      if (device.batteryCharge) {
        options.push({
          label: "devices.common.batteryCharge",
          value: (
            <>
              <FormattedNumber>{device.batteryCharge}</FormattedNumber>%
            </>
          )
        });
      }
    }

    if (device.type === DEVICE_TYPE.NET) {
      options.push({
        label: "devices.common.identCode",
        value: device.ident
      });

      options.push({
        label: "devices.common.host",
        value: `${device.host ? device.host : ""}:${
          device.port ? device.port : ""
        }`
      });
    }

    options.push({
      label: "devices.common.lastReportedAt",
      value: <FormattedDate fallback="-">{device.lastReportedAt}</FormattedDate>
    });

    return options;
  }, [device]);

  const connectedProbes = device.sensors.filter(
    sensor => sensor.type === SENSOR_TYPE.PROBE && sensor.isConnected
  );

  const connectedCounters = device.sensors.filter(
    sensor => sensor.type === SENSOR_TYPE.COUNTER && sensor.isConnected
  );

  return (
    <Status
      listOptions={getOptions()}
      probes={connectedProbes}
      counters={connectedCounters}
      device={device}
    />
  );
});

export default StatusContainer;
